<template>
  <div class="content content3 flex-column" style="padding: 10px">
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="userPageList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
        <el-table-column prop="name" type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="roleType" label="角色类型">
          <template slot-scope="{row}">
            <span>{{ row.roleType | role2name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="userListTotal > 10"
          background
          :current-page.sync="searchUserValue.pageCurrent"
          :page-size="searchUserValue.pageSize"
          :total="userListTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apis from "./apis2.js"

export default {

  name: 'jurisdiction',

  components: {
  },

  props: {
  },

  data() {
    return {
      userPageList: [],
      multipleSelection: [],
      searchUserValue: {
        departmentCode: '',
        pageCurrent: 1,
        pageSize: 10
      },
      userListTotal: 0
    }
  },

  filters: {
    role2name(role) {
      if (role == 'admin') return '超级管理员'
      if (role == 'company') return '公司层角色'
      if (role == 'project') return '项目层角色'
    }
  },
  computed: {
    ...mapGetters(['companyId'])
  },

  watch: {
    companyId() {
      this.getUserPageList()
    }
  },

  mounted() {
    if(this.companyId) this.getUserPageList()
  },

  methods: {
    async getUserPageList() {
      this.searchUserValue.departmentCode = this.companyId
      const data = await Apis.findJsPage(this.searchUserValue)
      // console.log(data)
      this.userPageList = data.list
      this.userListTotal = data.total
    },
    // 切换页码
    async handleSizeChangeData(val) {
      // console.warn(val)
      this.searchUserValue.pageCurrent = val
      this.getUserPageList().async
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
